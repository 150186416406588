import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  Grid,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import {
  useAttachments,
  useBreakpoint,
  useSelectedOrganization,
  useSnackbar,
  useTranslations,
  useUser,
} from "hooks";
import React, { useCallback, useEffect, useRef, useState } from "react";
import DialogHeader from "./DialogHeader";
import {
  AddToDrive,
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
  UploadFile,
} from "@mui/icons-material";
import { getTranslation } from "common";
import CommentsSection, {
  Comment,
  CompleteComment,
} from "components/CommentsSection";
import { flownodesApi, organizationsApi, processesApi } from "api";
import { getLabelDataFromJsonTags, getUserData, toBase64 } from "common/utilities";
import AttachmentList from "components/AttachmentList";
import { TaskCardButtonDialog } from "./TaskCardButtonsDialog";
import { Priority } from "hooks/usePriorities";
import { SequenceFlow, Task } from "model/activitiesModel";
import LabelManager from "../labels/LabelManager";
import FieldsList from "components/FieldsList";
import Labels from "components/labels/Labels";
import useDrivePicker from "react-google-drive-picker";
import useAppservices from "hooks/useAppservices";
import { useGoogleLogin } from "@react-oauth/google";
import axios, { AxiosError } from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { UserData } from "hooks/useUserDataKeys";

type SupportedTypes =
  | "document"
  | "spreadsheets"
  | "presentation"
  | "drawing"
  | "folder";
interface BaseTaskDetailDialogProps {
  open: boolean;
  doing: boolean;
  onClose: () => void;
  nextButtonLabel?: string;
  undoButtonLabel?: string;
  handleNextStep?: (task: Task) => void;
  handleBackStep?: (task: Task) => void;
  handleSequenceFlowChoice?: (task: Task, sequenceFlow: SequenceFlow) => void;
  administrator?: boolean;
  task?: Task;
  readonly?: boolean;
  onPriorityUpdate?: (taskId: number, newPriority: Priority) => void;
  refresh?: () => void;
}

// type Sections = "userdata" | "comments" | "attachments";
const TaskDetailDialog: React.FC<BaseTaskDetailDialogProps> = ({
  open,
  doing,
  onClose,
  task,
  readonly = false,
  handleSequenceFlowChoice,
  handleNextStep,
  handleBackStep,
  nextButtonLabel,
  undoButtonLabel,
  administrator,
  onPriorityUpdate,
  refresh,
}) => {
  const user = useUser();
  const [openPicker, authResponse] = useDrivePicker();
  const [isPickerOpen, setIsPickerOpen] = useState(false); //non so se è proprio il massimo, vai a riga 441
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [menuPhase, setMenuPhase] = useState<"main" | "create">("main");
  const redoGoogleDoc = useRef<SupportedTypes | null>(null);
  const { appservices, handleLinkAppservice } = useAppservices();
  // const latestPicker = useRef<HTMLElement>();
  //come su MUI, può andare? (ho solo cambiato da open a menuOpen)
  //https://mui.com/material-ui/react-menu/
  const menuOpen = Boolean(anchorEl);

  const authorizedEmails = [
    "fabio.moschella@gmail.com",
    "f.bertola@flowenti.com",
    "f.moschella@flowenti.com",
    "d.amici@bittime.it",
    "d.farisato@bittime.it",
  ];
  const isAuthorized = authorizedEmails.includes(
    user?.email.toLowerCase() || ""
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setMenuPhase("main");
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setMenuPhase("main");
    setIsPickerOpen(false);
    // latestPicker.current?.remove()
    document.querySelectorAll(".picker").forEach((el) => el.remove());
  }, []);

  const handleOpenCreateMenu = () => {
    setMenuPhase("create");
  };

  const handleBackToMainMenu = () => {
    setMenuPhase("main");
  };

  //https://stackoverflow.com/questions/11412497/what-are-the-google-apps-mime-types-in-google-docs-and-google-drive
  const getIdTypeFromMimeType = (mimeType: string) => {
    switch (mimeType) {
      case "application/vnd.google-apps.folder":
        return 21;
      case "application/vnd.google-apps.document":
        return 22;
      case "application/vnd.google-apps.spreadsheet":
        return 23;
      case "application/vnd.google-apps.presentation":
        return 24;
      case "application/vnd.google-apps.drawing":
        return 25;
      default:
        return 20;
    }
  };

  const failureGoogle: () => void = () => {
    console.error("fail");
  };
  const googleLogin = useGoogleLogin({
    onSuccess: async (e) => {
      const data = await handleLinkAppservice(e, 1);
      const token = data?.token;
      if (redoGoogleDoc.current && token) {
        await createAndUploadGoogleDoc(redoGoogleDoc.current, token);
      }
      redoGoogleDoc.current = null;
    },
    onError: failureGoogle,
    flow: "auth-code",
    enable_serial_consent: false,
    scope:
      "https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/presentations",
  });

  const handleOpenPicker = () => {
    setIsPickerOpen(true);
    openPicker({
      locale: user?.i18n,
      clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID2 || "",
      developerKey: process.env.REACT_APP_GOOGLE_DEVELOPER_KEY || "",
      viewId: "DOCS",
      token: authResponse?.access_token,
      showUploadView: true,
      // showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      callbackFunction: async (data) => {
        //dovrebbe essere giusta sta callback asincrona
        if (data.action === "picked") {
          const files = data.docs;
          let allFiles = files.map(async (file) => {
            //otteniamo i files
            // await fetch(
            //   `https://www.googleapis.com/drive/v3/files/${file.id}?alt=media`
            // );
            const payload = {
              idorganization: organizationId,
              filename: file.name,
              cloudlink: `https://drive.google.com/file/d/${file.id}/view`,
              idtype: getIdTypeFromMimeType(file.mimeType), //qui è dinamico, mentre sotto è statico per le folders
              description: file.description,
            };
            //mandiamo i dati
            return flownodesApi.post(
              `${task?.idprocesstoken}/attachments`,
              payload
            );
          });
          // aspettiamo tutte le operazioni di upload
          await Promise.all(allFiles);
          // classica snackbarina
          sendSnack({
            type: "success",
            message: getTranslation(
              translations,
              "generic.fileuploadedsuccessfully"
            ),
          });
          // recuperiamo i nuovi allegati
          getAttachments();
        }
        if (data.action === "cancel" || data.action === "picked") {
          setIsPickerOpen(false); // a seconda di che azione compie l'utente chiudiamo il picker
          handleClose(); // chiudiamo anche il menu, però è un po' paciugoso?
        }
      },
    });
  };

  const handleFolderPicker = () => {
    setIsPickerOpen(true);
    openPicker({
      locale: user?.i18n,
      clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID2 || "",
      developerKey: process.env.REACT_APP_GOOGLE_DEVELOPER_KEY || "",
      viewId: "FOLDERS",
      token: authResponse?.access_token,
      //  showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      setSelectFolderEnabled: true,
      setIncludeFolders: true,
      callbackFunction: async (data) => {
        if (data.action === "picked") {
          const folders = data.docs.filter(
            (doc) => doc.mimeType === "application/vnd.google-apps.folder"
          );
          let allFolders = folders.map(async (folder) => {
            const payload = {
              idorganization: organizationId,
              filename: folder.name, //qua ho dovuto fare così perché non vedevo foldername nella console
              cloudlink: `https://drive.google.com/drive/folders/${folder.id}`,
              description: folder.description || "",
              idtype: 21,
            };
            return flownodesApi.post(
              `${task?.idprocesstoken}/attachments`,
              payload
            );
          });
          await Promise.all(allFolders);
          sendSnack({
            type: "success",
            message: getTranslation(
              translations,
              "generic.folderuploadedsuccessfully"
            ),
          });
          getAttachments();
        }
        if (data.action === "cancel" || data.action === "picked") {
          setIsPickerOpen(false);
          handleClose();
        }
      },
    });
  };

  useEffect(() => {
    const setPickerStyles = () => {
      const pickers = document.querySelectorAll(
        ".picker-dialog"
      ) as NodeListOf<HTMLElement>;
      if (pickers.length > 0) {
        const currentPicker = pickers[pickers.length - 1];
        currentPicker.style.zIndex = "99999";
      }
    };
    if (authResponse && isPickerOpen) {
      setTimeout(() => {
        setPickerStyles();
      });
    }
  }, [isPickerOpen, authResponse]);

  const createGoogleDoc = async (type: string, accessToken: string) => {
    try {
      let mimeType = "";
      // let requestBody = {};

      switch (type) {
        case "folder":
          mimeType = "application/vnd.google-apps.folder";
          break;
        case "spreadsheets":
          mimeType = "application/vnd.google-apps.spreadsheet";
          break;
        case "presentation":
          mimeType = "application/vnd.google-apps.presentation";
          break;
        case "document":
          mimeType = "application/vnd.google-apps.document";
          break;
        case "drawing":
          mimeType = "application/vnd.google-apps.drawing";
          break;
        default:
          throw new Error(
            getTranslation(translations, "google.file.not.supported")
          );
      }
      try {
        const response = await axios.post(
          "https://content.googleapis.com/drive/v3/files?supportsTeamDrives=true&fields=webViewLink%2Cname&alt=json",
          {
            mimeType,
            name: task?.flownodename,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        // response.status
        return { docData: response.data, mimeType };
      } catch (error: any) {
        const _error: AxiosError = { ...error };
        if (_error.response?.status && _error.response?.status === 401) {
          redoGoogleDoc.current = type;
          googleLogin();
        }
      }
    } catch (error) {
      //   console.error("Errore durante la creazione del documento:", error);
    }
    return { docData: null, mimeType: null };
  };

  const createAndUploadGoogleDoc = async (
    type: SupportedTypes,
    access_token: string | null = null
  ) => {
    if (!appservices[1]) {
      sendSnack({
        type: "error",
        message: getTranslation(translations, "google.auth.failed.for.doc"),
      });
      return;
    }
    try {
      const { docData, mimeType } = await createGoogleDoc(
        type,
        access_token || appservices[1]
      );
      if (!docData) {
        sendSnack({
          type: "error",
          message: getTranslation(translations, "google.doc.create.error"),
        });
        return;
      }

      const payload = {
        idorganization: organizationId,
        filename: docData.name,
        cloudlink: docData.webViewLink,
        idtype: getIdTypeFromMimeType(mimeType || ""),
        description: `${getTranslation(
          translations,
          "google.doc.auto.created"
        )}: ${docData.name}`,
      };

      await flownodesApi.post(`${task?.idprocesstoken}/attachments`, payload);
      sendSnack({
        type: "success",
        message: getTranslation(
          translations,
          "generic.fileuploadedsuccessfully"
        ),
      });
      getAttachments();
    } catch {
      sendSnack({
        type: "error",
        message: getTranslation(translations, "generic.erroroccured"),
      });
    }
  };

  let organizationId: number | null | undefined = null;
  // const [maxWidth, setMaxWidth] = useState<number>(0);
  const [actionsWidth] = useState<number>(0);
  const isMobile = useBreakpoint("down", "sm");
  const selectedOrganization = useSelectedOrganization();
  if (selectedOrganization) {
    organizationId = selectedOrganization.id;
  }
  const { sendSnack } = useSnackbar();
  const translations = useTranslations();
  const [comments, setComments] = useState<CompleteComment[]>([]);
  const [attachments, , getAttachments, images, histories] = useAttachments(
    task || null
  );

  const [showMore, setShowMore] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [allPriorities, setAllPriorities] = useState<Priority[]>([]);
  const [selectedPriority, setSelectedPriority] = useState<Priority | null>(
    null
  );

  const toggleShowMore = () => {
    setShowMore((current) => !current);
  };

  const getComments = useCallback(async () => {
    if (task) {
      try {
        const response = await processesApi.get(`${task.idprocess}/comments`);
        setComments([...response.data.comments]);
      } catch {
      } finally {
      }
    }
  }, [task]);

  const onComment = async ({ comment }: Comment) => {
    if (task && comment) {
      try {
        await flownodesApi.post(`${task.idprocesstoken}/comments`, {
          comment,
        });
        getComments();
      } catch {
      } finally {
      }
    }
  };

  const handleUploadFile = async (files: FileList | null) => {
    if (task) {
      // setLoading(true);
      let promises = [];
      let hasError = false;
      try {
        if (files) {
          for (let file of Array.from(files)) {
            const payload = {
              idorganization: organizationId,
              filename: file.name,
              cloudlink: "",
              idtype: 1,
              description: file.name,
              blob: await toBase64(file),
            };
            promises.push(
              flownodesApi.post(`${task.idprocesstoken}/attachments`, payload)
            );
          }
          try {
            await Promise.all(promises);
          } catch {
            hasError = true;
          } finally {
            // setLoading(false);
          }
          if (!hasError) {
            sendSnack({
              type: "success",
              message: getTranslation(
                translations,
                "generic.fileuploadedsuccessfully"
              ),
            });
          }
          getAttachments();
        }
      } finally {
      }
    }
  };

  const fetchOrganizationPriorities = useCallback(async () => {
    // setLoading(true);
    const response = await organizationsApi.get(
      `/${organizationId}/priorities`
    );
    if (response && response.data && response.data.priorities) {
      const data: Priority[] = response.data.priorities;
      setAllPriorities(data);
      // setLoading(false);
    }
  }, [organizationId]);

  useEffect(() => {
    if (open) {
      fetchOrganizationPriorities();
      getComments();/// commento di Fede , ho la fede che funziona
    }
  }, [fetchOrganizationPriorities, open]);

  useEffect(() => {
    if (task && task.priority_name) {
      const priority = allPriorities.find(
        (element) => element.name === task.priority_name
      );
      setSelectedPriority(priority || null);
    }
  }, [allPriorities, task]);

  const updatePriority = async (newPriority: Priority) => {
    if (task) {
      try {
        const { data } = await flownodesApi.put(
          `/${task.idprocess}/priorities/${newPriority.id}`,
          {}
        );
        setSelectedPriority(newPriority);
        //callback per aggiornare myactivitiesmyactivities
        onPriorityUpdate && onPriorityUpdate(task.idprocesstoken, newPriority);
        sendSnack({
          type: "success",
          message: data.responsemessage,
        });
      } catch (error) {
      } finally {
      }
    }
  };

  const handlePriorityChange = (event: SelectChangeEvent<string | number>) => {
    const newPriority = allPriorities.find(
      (priority) => priority.id === Number(event.target.value)
    );
    if (newPriority) {
      updatePriority(newPriority);
    }
  };

  //   await flownodesApi.put(
  //     `/${task?.idprocess}/priorities/${newPriority.id}`,
  //     {}
  //   );

  //   await activitiesApi.get("/canget");
  // };

  // type Panel = "file" | "userdata" | "comments" | null;
  // const [openPanel, setOpenPanel] = useState<Panel>("comments");
  // const iconButtonStyle = {
  //   color: "rgba(84, 189, 193, 1)",
  //   borderRadius: "6px",
  //   padding: "2px",
  //   marginRight: "8px",
  //   backgroundColor: "rgba(84, 189, 193, 0.3)",
  //   "&:hover": {
  //     backgroundColor: "rgba(84, 189, 193, 0.4)",
  //   },
  // };

  // const CustomIconButton = styled(IconButton)(iconButtonStyle);
  // const icons = {
  //   ExpandMoreRounded: <ExpandMoreRounded />,
  // };
  const [isLabelManagerShown, setIsLabelManagerShown] =
    useState<HTMLElement | null>(null);
  const handleLabelManagerClose = () => {
    setIsLabelManagerShown(null);
  };

  const [userdataEntries, setUserDataEntries] = useState<{[key:string]: UserData}>({});

  useEffect(() => {
    // Parsing di userdata dal JSON di task
    const parsedUserData = getUserData(task?.userdata);
    const userDataEntries = Object.fromEntries(
      Object.entries(parsedUserData).map(([key, item]) => [
        key,
        item
      ])
    );
    setUserDataEntries(userDataEntries);
    Object.entries(parsedUserData).forEach(([key, value]) => {
      if ((value as UserData).inCard) {
        localStorage.setItem(`userdata-per-${task?.idprocess}`, key);
      }
    });
  }, [task]);

  const handleSaveUserdata = async (
    payload: { [key: string]: UserData; }
  ) => {
    try {
      // const payload = Object.fromEntries(
      //   Object.entries(newUserdata).map(([key, value]) => [
      //     key,
      //     value
      //   ])
      // );
      const { data } = await processesApi.put(
        `/${task?.idprocess}/userdata`,
        payload
      );
      // setUserDataEntries(userdataEntries)
      sendSnack({
        message: data.responsemessage,
        type: "success",
      });
      refresh?.();
    } catch {
    } finally {
    }
  };

  useEffect(() => {
    if (open) {
      getComments();
      getAttachments();
      // getUserdata();
    }
  }, [getComments, getAttachments, open]);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={isMobile}
      fullWidth
      maxWidth="md"
      scroll="paper"
    >
      <DialogHeader onClose={onClose}>
        <Box sx={{ marginLeft: 4 }}>
          {task && (
            <Grid display="flex" alignItems="center">
              <Grid marginRight={5}>
                <Typography variant="h2">
                  {task.flownodename} - {task.description}
                </Typography>
              </Grid>
              <Grid>
                <Labels
                  labelCompleteMode
                  labels={getLabelDataFromJsonTags(task?.jsontag)}
                />
              </Grid>
            </Grid>
          )}
          {task && (
            <Typography variant="body2" sx={{ mt: 1 }}>
              {task.processmodelname}
            </Typography>
          )}
        </Box>
      </DialogHeader>
      <DialogContent sx={{ p: 5 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={10}>
            <Stack spacing={1} direction="column">
              <Stack style={{ width: "25%" }} sx={{ pt: 1 }}>
                {selectedPriority && (
                  <Select
                    value={selectedPriority.id}
                    onChange={handlePriorityChange}
                    displayEmpty
                    disabled={readonly || !doing}
                    renderValue={(selected) => (
                      <Chip
                        label={selectedPriority.name}
                        color="primary"
                        sx={{
                          background: "transparent",
                          fontSize: 18,
                          fontWeight: "bold",
                          minWidth: 45,
                          px: 0, //mi sembra più pulito
                          py: 0,
                          borderRadius: 0,
                          border: "none",
                          color: "black",
                        }}
                      />
                    )}
                    sx={{
                      "& .MuiSelect-select": {
                        padding: 0, // Rimuoviamo il padding dentro al select
                      },
                    }}
                  >
                    {allPriorities.map((priority) => (
                      <MenuItem key={priority.id} value={priority.id}>
                        {priority.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </Stack>
              <FieldsList
                canManage={doing && !readonly}
                selectedProcess={task?.idprocess || null}
                value={userdataEntries}
                onChange={setUserDataEntries}
                handleSaveUserdata={handleSaveUserdata}
              />
              {/* File allegati */}
              {attachments.length > 0 && (
                <Stack spacing={1} direction="column" alignItems="flex-start">
                  <Box display="flex" width="90%">
                    <Typography variant="h6" sx={{ fontSize: "1.125rem" }}>
                      {getTranslation(
                        translations,
                        "settings.attachments.label"
                      )}
                    </Typography>
                  </Box>
                  <Stack direction="column" alignItems="flex-start">
                    <AttachmentList
                      canManage={doing && !readonly}
                      // attachments={attachments}
                      attachments={attachments.slice(
                        0,
                        showMore ? attachments.length : 4
                      )}
                      // fatherWidth={maxWidth}
                      knownWidth={actionsWidth}
                      getAttachments={getAttachments}
                      images={images}
                      histories={histories}
                    />
                    {attachments.length > 4 ? (
                      <Button onClick={toggleShowMore}>
                        {showMore
                          ? getTranslation(
                            translations,
                            "settings.attachments.hide.label"
                          )
                          : getTranslation(
                            translations,
                            "settings.attachments.show.label"
                          )}
                      </Button>
                    ) : null}
                  </Stack>
                </Stack>
              )}
              {/* Comments */}
              <Stack spacing={1} direction="column" alignItems="flex-start">
                <Typography variant="h6" sx={{ fontSize: "1.125rem", mb: 2 }}>
                  {getTranslation(translations, "settings.comments.label")}
                </Typography>
                <CommentsSection
                  canWrite={doing && !readonly}
                  onComment={onComment}
                  comments={comments}
                />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={2} sx={{ pl: 2 }}>
            {task && (
              <Stack spacing={1}>
                {handleNextStep &&
                  (!task?.sequenceflows || task.sequenceflows?.length <= 0) && (
                    <Button
                      fullWidth
                      id={`process-next-button-${task.idprocesstoken}`}
                      style={{ display: "flex" }}
                      size="small"
                      endIcon={<ArrowForwardIosRounded />}
                      variant="outlined"
                      onClick={() => {
                        handleNextStep(task as Task);
                        onClose();
                      }}
                    >
                      {nextButtonLabel ||
                        getTranslation(translations, "process.action.next")}
                    </Button>
                  )}
                {task.sequenceflows &&
                  task.sequenceflows.length > 0 &&
                  handleSequenceFlowChoice && (
                    <TaskCardButtonDialog
                      task={task as Task}
                      handleSequenceFlowChoice={handleSequenceFlowChoice!}
                    />
                  )}
                {handleBackStep && (
                  <Box>
                    <Button
                      fullWidth
                      id={`process-back-button-${task?.idprocesstoken}`}
                      size="small"
                      endIcon={<ArrowBackIosRounded />}
                      variant="outlined"
                      onClick={() => {
                        handleBackStep(task as Task);
                        onClose();
                      }}
                    >
                      {undoButtonLabel ||
                        getTranslation(translations, "process.action.undo")}
                    </Button>
                  </Box>
                )}

                {!readonly && doing && (
                  <>
                    {/* <Button
                      endIcon={<AddToDrive />}
                      size="small"
                      variant="semitransparent"
                      onClick={() => handleOpenPicker()}
                    >
                      {getTranslation(
                        translations,
                        "pm.startprocess.button.uploadfile.drive"
                      )}
                    </Button> */}
                    <Button
                      endIcon={<UploadFile />}
                      size="small"
                      variant="outlined"
                      component="label"
                    >
                      {getTranslation(
                        translations,
                        "pm.startprocess.button.uploadfile.caption"
                      )}
                      <input
                        id="upload-attachment-button-2"
                        type="file"
                        multiple
                        hidden
                        onChange={({ target }) =>
                          handleUploadFile(target.files)
                        }
                      />
                    </Button>
                    <Button
                      //id=""
                      //endIcon={ }
                      size="small"
                      variant="outlined"
                      component="label"
                      onClick={(e) => setIsLabelManagerShown(e.currentTarget)}
                    >
                      {getTranslation(translations, "generic.labels.caption")}
                    </Button>
                    {isAuthorized && (
                      <>
                        <hr style={{ marginTop: "20px" }} />
                        <Typography style={{ fontWeight: "bold" }}>
                          {getTranslation(
                            translations,
                            "general.cloud.services"
                          )}
                        </Typography>
                        <Box>
                          <Button
                            id="basic-button"
                            aria-controls={menuOpen ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={menuOpen ? "true" : undefined}
                            onClick={handleClick}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-around",
                              padding: 0,
                            }}
                          >
                            <AddToDrive />
                            {getTranslation(
                              translations,
                              "pm.startprocess.button.uploadfile.drive"
                            )}
                          </Button>
                        </Box>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={menuOpen}
                          onClose={handleClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          {menuPhase === "main" ? (
                            <div>
                              <MenuItem onClick={handleOpenPicker}>
                                {getTranslation(
                                  translations,
                                  "pm.startprocess.button.upload.file.drive"
                                )}
                              </MenuItem>
                              <MenuItem onClick={handleFolderPicker}>
                                {" "}
                                {getTranslation(
                                  translations,
                                  "pm.startprocess.button.upload.folder.drive"
                                )}
                              </MenuItem>
                              <MenuItem onClick={handleOpenCreateMenu}>
                                {getTranslation(
                                  translations,
                                  "google.create.and.upload.file"
                                )}
                              </MenuItem>
                            </div>
                          ) : (
                            <div>
                              {appservices[1] ? (
                                <React.Fragment>
                                  <MenuItem onClick={handleBackToMainMenu}>
                                    <ArrowBackIcon />
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      createAndUploadGoogleDoc("document")
                                    }
                                  >
                                    {getTranslation(
                                      translations,
                                      "general.google.doc"
                                    )}
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      createAndUploadGoogleDoc("spreadsheets")
                                    }
                                  >
                                    {" "}
                                    {getTranslation(
                                      translations,
                                      "general.google.sheet"
                                    )}
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      createAndUploadGoogleDoc("presentation")
                                    }
                                  >
                                    {getTranslation(
                                      translations,
                                      "general.google.presentation"
                                    )}
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      createAndUploadGoogleDoc("drawing")
                                    }
                                  >
                                    {getTranslation(
                                      translations,
                                      "general.google.drawing"
                                    )}
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      createAndUploadGoogleDoc("folder")
                                    }
                                  >
                                    {getTranslation(
                                      translations,
                                      "general.google.folder"
                                    )}
                                  </MenuItem>
                                </React.Fragment>
                              ) : (
                                <Stack sx={{ p: 1, maxWidth: 250 }}>
                                  <Typography fontSize={13}>
                                    {getTranslation(
                                      translations,
                                      "link.flowenti.drive.account"
                                    )}
                                  </Typography>
                                  <Button
                                    size="small"
                                    variant="contained"
                                    onClick={googleLogin}
                                  >
                                    {getTranslation(
                                      translations,
                                      "link.flowenti.drive.account.button"
                                    )}
                                  </Button>
                                </Stack>
                              )}
                            </div>
                          )}
                        </Menu>
                      </>
                    )}
                  </>
                )}
              </Stack>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      {isLabelManagerShown && (
        <LabelManager
          idprocess={task?.idprocess}
          open={isLabelManagerShown}
          handleClose={handleLabelManagerClose}
        />
      )}
    </Dialog>
  );
};
export default TaskDetailDialog;
